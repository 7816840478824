import AnimationRevealPage from "helpers/AnimationRevealPage";
//import ContactForm from "./ContactForm";
//import FooterPage from "components/Footer";
import React from "react";
import SEO from "components/seo/SEO";
import ContactUsForm from "./ContactUsForm";
//eslint-disable-next-line

//meta title
document.title="Contact Us | Weg2De";
function ContactUsPage() {
  return (
    <AnimationRevealPage>
      <SEO
        title="Contact Us - Weg2De"
        description="Learn German Online | Study Work and Live in Germany | Ausbildung in Germany | Best Online German School"
        name="Weg2De - Contact Us"
        type=""
      />
      <ContactUsForm />
     {/*<ContactForm />
     <FooterPage /> */}
    </AnimationRevealPage>
  );
}

export default ContactUsPage;
