import AdmissionIcon from "../images/uni-admission.svg";
import ApIcon from "../images/job-search.svg";
import CandidateSearchIcon from "../images/candidate-search.svg";
import CareerIMG from "../images/job-search.svg";
import EntwicklerIcon from "../images/webdev.svg";
import GermanyIcon from "../images/volunteer.svg";
import { HiBadgeCheck } from "react-icons/hi";
import { HighlightedText } from "components/misc/Headings";
import RANA from "../images/rana.jfif";
//import IMG2 from "../images/b.png";
import IndividualIMG from "../images/co-work.svg";
import IshaqIMG from "../images/ishaq.webp";
import KfzIcon from "../images/mechanic.svg";
import MigrationIcon from "../images/migration.svg";
import NurseIcon from "../images/nurse.svg";
import OnlineGermanIcon from "../images/online-german.svg";
import OnlineLearningIMG from "../images/online_learning.svg";
import ProfessionalIMG from "../images/professional.svg";
import React from "react";
import RelocationIcon from "../images/german-courses.svg";
import SchulabsolventIcon from "../images/carpenter.svg";
import TravelIcon from "../images/travel.svg";
import VisaIcon from "../images/Approved-Visa.svg";
import WorkIcon from "../images/ausbildung-prog.svg";
//import { SectionDescription } from "components/misc/Typography";
//import { SectionHeading } from "components/misc/Headings";
import tw from "twin.macro";
//import { SectionDescription } from "components/misc/Typography";
//const LeftColumn = tw.section`md:pr-5 relative flex-1`;
//const RightColumn = tw.section`md:pl-5 relative flex-1`;
//const TwoColumn = tw.section`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center `;
const IconWrapper = tw.div` flex items-center text-primary-500`;
const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`list-disc md:list-none text-primary-500`;
const customAns = (
  <>
    If you have more questions or need assistance, please contact us at{" "}
    <HighlightedText>info@weg2de.com</HighlightedText> or WhatsApp{" "}
    <HighlightedText>+4917666572214</HighlightedText>
  </>
);

export const WhatWeDoData = [
  {
    imageSrc: CandidateSearchIcon,
    linkTo: "/arbeitgeber",
    to: "arbeitgeber",
    title: "Recruiting",
    description:
      "Looking to Hire IT, Technicians, Healthcare Professionals, Hearing Aid Specialists, or Apprentices? Talk to us today. We handle the entire process—from recruiting and language training to visa assistance and relocation to Germany and DACH region. Curios how we can help?",
  },
  {
    imageSrc: MigrationIcon,
    linkTo: "/candidates",
    to: "candidates",
    title: `Immigration & Relocation`,
    description:
      "Planning to Move to Germany or the DACH Region? Weg2De is Here to Help! Whether you're seeking study, apprenticeship, or work opportunities, Weg2De is your trusted partner for a smooth transition. We're here to support you every step of the way. Contact us today!",
  },
  {
    imageSrc: OnlineGermanIcon,
    linkTo: "/akademie",
    to: "akademie",
    title: "German & Orientation Courses",
    description:
      "Discover German language and orientation courses tailored to your specific travel objectives at Weg2De Akademie. Explore our offerings based on the purpose of your journey to Germany and the DACH region by clicking the link below to discover more.",
  },
];


export const portfolioServiceData = [
  {
    imageSrc: AdmissionIcon,
    linkTo: "/university-admission",
    to: "/university-admission",
    title: "University Admission",
    description:
      "Weg2De offers streamlined assistance in navigating the complexities of the application process, guiding individuals through the selection of suitable educational institutions and the meticulous submission of applications. Want to study in Germany?",
  },
  {
    imageSrc: RelocationIcon,
    linkTo: "/akademie",
    to: "/akademie",
    title: `German & Orientation Courses`,
    description:
      "Discover German language and orientation courses tailored to your specific travel objectives at Weg2De Akademie. Explore our offerings based on the purpose of your journey to Germany and the DACH region by clicking the link below to discover more.",
  },
  {
    imageSrc: ApIcon,
    linkTo: "/job-search-worksop",
    to: "/job-search-workshop",
    title: "Job Search Workshop",
    description:
      "Our Job Search Workshop equips you with the skills and strategies needed to succeed in the German job market. From crafting a standout resume to mastering interview techniques, this workshop is designed to help you land your desired job. Contact us or book a session.",
  },
  {
    imageSrc: WorkIcon,
    linkTo: "/apprenticeship-program",
    to: "/apprenticeship-program",
    title: "Apprenticeship Program",
    description:
      "The Weg2De Apprenticeship Program is a comprehensive and meticulously structured preparatory program, designed to equip participants with the essential skills and knowledge required for a successful transition into the German workforce.",
  },
  {
    imageSrc: GermanyIcon,
    linkTo: "/volunteering-in-germany",
    to: "/volunteering-in-germany",
    title: "Volunteering in Germany",
    description:
      "Through programs like FSJ and BFD, Weg2De foster personal growth and societal contributions, creating immersive and meaningful participant experiences. You can volunteer in Germany for 6 months or a year in Education, Care and many areas.",
  },
  {
    imageSrc: TravelIcon,
    linkTo: "/german-health-insurances",
    to: "/german-health-insurances",
    title: "German Health Insurances",
    description:
      "Germany has one of the most comprehensive healthcare systems in the world, ensuring that residents, workers, and students are well-protected. The system is divided into public (gesetzliche kv) and  private health insurance.",
  },
];


export const servicesCardData = [
  {
    imageSrc: EntwicklerIcon,
    title: "IT-Fachkräfte",
    description:
    <ListWrapper>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
              <b>Berufserfahrung</b>: mind. 3j
              </TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
              <b>Abschluss</b>: B.Sc. 
              </TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                <b>Deutsch</b>: mind. A1 - B1
              </TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                <b>Englisch</b>: mind. B2
              </TextItem>
            </IconWrapper>
          </ListItem>
    </ListWrapper>

  },
  {
    imageSrc: NurseIcon,
    title: "Pflegefachkräfte",
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Berufserfahrung</b>: mind. 2j
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Abschluss</b>: B.Sc.
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Deutsch</b>: mind. B1 - B2
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Englisch</b>: mind. B2
        </TextItem>
      </IconWrapper>
    </ListItem>
</ListWrapper>
  },
  {
    imageSrc: KfzIcon,
    title: "Handwerker",
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Berufserfahrung</b>: mind. 2j
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Abschl.</b>: Berufsausbildung
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Deutsch</b>: mind. A1 - B1
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Englisch</b>: mind. B2
        </TextItem>
      </IconWrapper>
    </ListItem>
</ListWrapper>
  },
  {
    imageSrc: SchulabsolventIcon,
    title: "Auszubildenden",
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        <b>Abschl.</b>: Schulabschluss 
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Leidenschaft & Interesse</b>
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Deutsch</b>: mind. B1 - B2
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Englisch</b>: mind. B2
        </TextItem>
      </IconWrapper>
    </ListItem>
</ListWrapper>
  },
];
export const defaultTestimonials = [
  {
    imageSrc:
      "https://images.unsplash.com/photo-1693410218147-67bcc4653e51?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHRyYXZlbGxpbmd8ZW58MHx8MHx8fDA%3D",
    profileImageSrc:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
    quote:
      "Wir haben mit Weg2De hervorragende Erfahrungen gemacht. Der Rekrutierungsprozess war effizient und transparent, und wir konnten innerhalb kurzer Zeit qualifizierte Kandidaten für unsere offenen Positionen finden. Besonders hervorzuheben ist die individuelle Beratung, die dafür gesorgt hat, dass die vorgeschlagenen Bewerber perfekt zu unseren Anforderungen passten. Wir werden definitiv auch in Zukunft auf diesen Service zurückgreifen.",
    customerName: "Diana",
    customerTitle: "Geschäftsführerin eines Pflegedienst in Hannover",
  },
  {
    imageSrc:
      "https://images.unsplash.com/36/xIsiRLngSRWN02yA2BbK_submission-photo-7.jpg?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHRyYXZlbGxpbmd8ZW58MHx8MHx8fDA%3D",
    profileImageSrc:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
    quote:
      "Die Zusammenarbeit mit Weg2De war ein voller Erfolg. Wir haben mehrere Techniker eingestellt und waren stets beeindruckt von der Qualität der Kandidaten. Der gesamte Prozess, von der Stellenausschreibung bis zur Einstellung, war reibungslos und professionell organisiert. Es war eine große Erleichterung, sich auf einen zuverlässigen Partner verlassen zu können, der unsere Anforderungen versteht und erfüllt.",
    customerName: "Lekan",
    customerTitle: "Geschäftsführer IOD-Digital UG",
  },
];

export const homepageFaqs = [
  {
    question: "I am interested in migrating to Germany, How can you help?",
    answer:
      "At Weg2De, we offer a comprehensive range of services to support your migration journey to Germany: <br>German Language Courses: We provide tailored language training to help you achieve the proficiency needed for living, studying, or working in Germany. \n\nUniversity Admissions: Our team assists with university applications, helping you find and secure a spot in a German institution that aligns with your academic goals.Ausbildung (Vocational Training) Placements: We guide you in finding and applying for vocational training programs (Ausbildung) that match your skills and career aspirations. Volunteering Opportunities: For those interested in gaining experience while giving back, we help connect you with volunteering positions across various sectors in Germany. Career Advice and Support: Whether you’re just starting out or looking to advance your career, we offer personalized advice and support to help you navigate the German job market. With our end-to-end assistance, you can confidently embark on your new journey to Germany.",
  },
  {
    question: "What is the process for applying to universities in Germany?",
    answer:
      "We assist with every step of the university application process, from selecting the right courses and institutions to ensuring all necessary documents are correctly submitted. We also provide guidance on meeting entry requirements and preparing for interviews.",
  },
  {
    question: "How can I improve my German language skills before moving?",
    answer:
      "We offer a range of German language courses tailored to your current proficiency level. These courses are designed to help you achieve the fluency needed for daily life, work, or academic pursuits in Germany.",
  },
  {
    question: "What is an Ausbildung, and how can I apply?",
    answer:
      "An Ausbildung is a form of vocational training that combines classroom education with hands-on work experience. We help you find suitable Ausbildung programs in your field of interest, assist with the application process, and guide you through securing a position.",
  },
  {
    question: "Can you assist with obtaining a visa for Germany?",
    answer:
      "Yes, we provide guidance and support throughout the visa application process, ensuring you have all the required documents and meet the necessary criteria to successfully obtain a visa for Germany.",
  },
  {
    question: "What kind of career support do you offer?",
    answer:
      "We offer personalized career advice, including resume building, interview preparation, and job search strategies tailored to the German job market. We also help connect you with potential employers and opportunities that align with your skills and experience.",
  },
];

export const teamData = [
 
  {
    id: 1,
    imageSrc: IshaqIMG,
    role: "Founder & CEO",
    name: "Ishaq Kokumo",
    bio: "Ishaq has professional background in IT and over 7 years of experience in migration services. His work focuses on helping individuals and companies navigate the complexities of migration and settlement in Germany. He is fluent in English, German, and Yoruba, allowing him connect with a diverse range of clients and partners. Ishaq's passion for bridging cultural and language gaps has been a key driver behind his efforts to make the migration process as seamless as possible for everyone involved.",
    mail: "ishaq.kokumo@weg2de.com",
    linkedInUrl: "https://www.linkedin.com/in/ishaqkokumo",
  },
  {
    id: 2,
    imageSrc: RANA,
    role: "Co-founder",
    name: "Rana Atmaca",
    bio: "Rana is the Co-founder of Weg2De and holds a B.Sc. in Audiology with over 5 years of experience working with companies in both Türkiye and Germany. Her expertise lies in providing exceptional support to individuals and companies in the audiology sector, ensuring top-notch service for our clients. Rana also specializes in catering to our Turkish-speaking customers, offering personalized guidance and assistance to meet their unique needs. Rana is fluent in English, German, and Turkish",
    mail: "rana.atmaca@weg2de.com",
    linkedInUrl: "https://www.linkedin.com/in/rana-atmaca-a21620190",
  },
  /*{{
    id: 3,
    imageSrc: IMG2,
    role: "Technical",
    name: "Steven Marcetti",
    bio: "lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip",
    mail: "stevenmarcetti@gmail.com",
    linkedInUrl: "https://www.linkedin.com/company/weg2de",
  },
  
    id: 4,
    imageSrc: IMG2,
    role: "Technical",
    name: "Steven Marcetti",
    bio: "lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip",
    mail: "stevenmarcetti@gmail.com",
    linkedInUrl: "https://www.linkedin.com/company/weg2de",
  },
  {
    id: 5,
    imageSrc: IshaqIMG,
    role: "Founder & CEO",
    name: "Ishaq Kokumo",
    bio: "lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip",
    mail: "ishaq.kokumo@weg2de.com",
    linkedInUrl: "https://www.linkedin.com/in/ishaqkokumo",
  },
  {
    id: 6,
    imageSrc: RANA,
    role: "Co-founder & HR",
    name: "John Doe",
    bio: "lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip",
    mail: "saheedadewaleshittu@gmail.com",
    linkedInUrl: "https://linkedin.com/in/saheed-shittu-56a16789",
  },*/
  
];
export const germanCoursesData = [
  {
    id: 1,
    imgSrc: OnlineLearningIMG,
    name: "German Online Group Course",
    levels: "A1, A2, B1, B2",
    duration: "8 Weeks",
    desc: "Our German Online Group Course has four levels: A1, A2, B1, and B2. Each level includes modules on speaking, listening, reading, and writing. Small class sizes ensure personalized attention and plenty of practice.",
    location: "Online Live Sessions",
    linkTo: "/german-online-group-course",
  },
  {
    id: 2,
    name: "German for Professionals",
    imgSrc: ProfessionalIMG,
    levels: "A1 - B2",
    duration: "6 - 9 Months",
    desc: "Our six-month German course which spans from A1 to B1 (or A1 to B2) and is tailored for IT, healthcare, engineering, and other professionals to build industry-specific language skills. Enhance your employment chances.",
    location: "Online Live Sessions",
    linkTo: "/german-for-professionals",
  },
  {
    id: 3,
    name: "Individual Courses",
    imgSrc: IndividualIMG,
    levels: "A1, A2, B1, B2",
    duration: "Varies",
    desc: "Our Individual Courses provide personalized German language training, ideal for both individuals and corporate clients. Tailored to your specific needs, our sessions help you achieve your language goals efficiently.",
    location: "",
    linkTo: "/individual-courses",
  },
];

export const careerCoachingData = [
  {
    id: 1,
    name: "Job Search Workshop",
    imgSrc: CareerIMG,
    levels: "Multiple Sessions",
    duration: "Weekly",
    desc: "Our Job Search Workshop equips you with the skills and strategies needed to succeed in the German job market. From crafting a standout resume to mastering interview techniques, this workshop is designed to help you land your desired job.",
    location: "Online Live Sessions",
    linkTo: "/job-search-workshop",
  },
  {
    id: 2,
    name: "Working & Living in Germany",
    imgSrc: EntwicklerIcon,
    levels: "Multiple Sessions",
    duration: "Weekly",
    desc: "Our Working & Living in Germany course prepares you for life in Germany by covering essential cultural insights, and practical guidance. Whether you're moving for work or study, this course equips you with the tools to navigate everyday life confidently.",
    location: "Online Live Sessions",
    linkTo: "/work-and-live-in-germany",
  },
  {
    id: 3,
    name: "Apprenticeship Program",
    imgSrc: WorkIcon,
    levels: "A1 - B2",
    duration: "9 Months",
    desc: "The Weg2De Apprenticeship Program is a comprehensive and meticulously structured preparatory program, designed to equip participants with the essential skills and knowledge required for a successful transition into the German workforce.",
    location: "Online Live Sessions",
    linkTo: "/apprenticeship-program",
  },
];

export const courseFaqs = [
  {
    question:
      "What is the minimum number of participants required for the course to take place?",
    answer: "The minimum number of participants required for the course is 7.",
  },
  {
    question: "What happens if the minimum number of participants is not met?",
    answer:
      "If the minimum number of participants is not met, the course will not take place. In such a case, we will do our best to offer you an alternative option in good time. If you do not wish to accept the suggested alternative, any payments already made will be promptly refunded.",
  },
  {
    question: "Is there a placement test for the course?",
    answer:
      "Yes, there is a placement test to ensure you are placed in the appropriate class.",
  },
  {
    question: "When will I receive the placement test?",
    answer: "You will receive the placement test after you book your course.",
  },
  {
    question: "How long do I have to complete and return the placement test?",
    answer: "You will have 3 days to complete and return the placement test.",
  },
  {
    question: "Is the completion and return of the placement test mandatory?",
    answer:
      "Yes, the timely completion and return of the placement test is a mandatory prerequisite for attending the course.",
  },
  {
    question: "What should I do if I have more questions or need assistance?",
    answer: customAns,
  },
];

export const courseFeeData = [
  { id: 1, level: "A1", module: "A1.1+A1.2", price: "300", currency: "€", duration: "(8 Weeks)", start: " > 28th October, 2024", morningsessions: " > 9:00am - 12:00pm CET", eveningsessions: " > 20:00pm - 22:00pm CET" },
  { id: 1, level: "A2", module: "A2.1+A2.2", price: "300", currency: "€", duration: "(8 Weeks)", start: " > 28th October, 2024", morningsessions: " > 9:00am - 12:00pm CET", eveningsessions: " > 20:00pm - 22:00pm CET" },
  { id: 1, level: "B1", module: "B1.1+B1.2", price: "400", currency: "€", duration: "(10 Weeks)", start: " > 18th November, 2024", morningsessions: " > 9:00am - 12:00pm CET", eveningsessions: " > 19:00pm - 22:00pm CET" },
  { id: 1, level: "B2", module: "B2.1+B2.2", price: "450", currency: "€" , duration: "(10 Weeks)", start: " > 18th November, 2024", morningsessions: " > 9:00am - 12:00pm CET", eveningsessions: " > 19:00pm - 22:00pm CET"},
];

export const G4pcourseFeeData = [
  { id: 1, level: "B1", module: " (G4P) A1 - B1", price: "1350", currency: "€", duration: "(7 Months)", },
  { id: 1, level: "B2", module: "(G4P) A1 - B2", price: "1750", currency: "€", duration: "(9 Months)", },
  // { id: 1, level: "B1", module: "A1 - B2", price: "1750", currency: "€" },
  // { id: 1, level: "B2", module: "B2.1+B2.2", price: "450", currency: "€" },
];

export const AusbildungFeeData = [
  { id: 1, level: "B1", module: " (WAPP) A1 - B1", price: "1250", currency: "€", duration: "(7 Months)", },
  { id: 1, level: "B2", module: "(WAPP) A1 - B2", price: "1650", currency: "€",  duration: "(9 Months)", }, 
  // { id: 1, level: "B1", module: "A1 - B2", price: "1750", currency: "€" },
  // { id: 1, level: "B2", module: "B2.1+B2.2", price: "450", currency: "€" },
];

export const CandidateCustomerCount = [
  {id: 1,  startCount: 0, endCount: 50, countDuration: 15,  title:"Students"},
  {id: 2,  startCount: 0, endCount: 25, countDuration: 15,  title:"Graduates"},
  {id: 3,  startCount: 0, endCount: 2, countDuration: 15,  title:"Semesters"},
  {id: 4,  startCount: 0, endCount: 10, countDuration: 15,  title:"Courses"},
]

export const CourseCustomerCount = [
  {id: 1,  startCount: 0, endCount: 20, countDuration: 15,  title:"Students"},
  {id: 2,  startCount: 0, endCount: 5, countDuration: 15,  title:"Graduates"},
  {id: 3,  startCount: 0, endCount: 1, countDuration: 15,  title:"Semester"},
  {id: 4,  startCount: 0, endCount: 3, countDuration: 15,  title:"Courses"},
]

export const hiringStepsData = [
  {id: 1, imgSrc:OnlineGermanIcon, title: "Anforderungen definieren", desc: "Teilen Sie uns Ihre Bedürfnisse und die Details der offenen Stelle mit. Wir erstellen Gemeinsam ein klares Anforderungsprofil, um die passenden Kandidaten für Ihr Unternehmen zu finden."},
  {id: 2, imgSrc:OnlineGermanIcon, title: "Empfehlung von Kandidaten", desc: "Wir starten eine gezielte Suche und Vorauswahl. Sie erhalten eine Auswahl qualifizierter Kandidaten, die sowohl fachlich als auch zur Ihr Unternehmenskultur passen."},
  {id: 3, imgSrc:OnlineGermanIcon, title: "Interview, Auswahl & Training", desc: "Wir Organisieren die Vorstellungsgespräche und Sie wählen den besten Kandidaten aus. Bei Bedarf bieten wir Deutschkurse und professionelles Training zur Vorbereitung an."},
  {id: 4, imgSrc:OnlineGermanIcon, title: "Visumantrag & Einreise", desc: "Wir unterstützen Sie beim gesamten Visumsprozess, übernehmen die Kommunikation mit Behörden und erleichtern den reibungslosen Übergang Ihre neue mitarbeiter nach Deutschland."},
  {id: 5, imgSrc:VisaIcon, title: "Integration & Onboarding", desc: "Wir begleiten den Integrationsprozess, unterstützen bei Wohnungssuche, Behördengängen und organisieren Deutschkurse sowie weiteres Training für einen erfolgreichen Start."},
];

export const generalFaqs = [
  {
    question: "What does Weg2De do?",
    answer:
      "At Weg2De, we offer a comprehensive range of services to support your migration journey to Germany: German Language Courses: We provide tailored language training to help you achieve the proficiency needed for living, studying, or working in Germany. University Admissions: Our team assists with university applications, helping you find and secure a spot in a German institution that aligns with your academic goals.Ausbildung (Vocational Training) Placements: We guide you in finding and applying for vocational training programs (Ausbildung) that match your skills and career aspirations. Volunteering Opportunities: For those interested in gaining experience while giving back, we help connect you with volunteering positions across various sectors in Germany. Career Advice and Support: Whether you’re just starting out or looking to advance your career, we offer personalized advice and support to help you navigate the German job market. With our end-to-end assistance, you can confidently embark on your new journey to Germany.",
  },
  {
    question: "What is the process for applying to universities in Germany?",
    answer:
      "We assist with every step of the university application process, from selecting the right courses and institutions to ensuring all necessary documents are correctly submitted. We also provide guidance on meeting entry requirements and preparing for interviews.",
  },
  {
    question: "How can I improve my German language skills before moving?",
    answer:
      "We offer a range of German language courses tailored to your current proficiency level. These courses are designed to help you achieve the fluency needed for daily life, work, or academic pursuits in Germany.",
  },
  {
    question: "What is an Ausbildung, and how can I apply?",
    answer:
      "An Ausbildung is a form of vocational training that combines classroom education with hands-on work experience. We help you find suitable Ausbildung programs in your field of interest, assist with the application process, and guide you through securing a position.",
  },
  {
    question: "Can you assist with obtaining a visa for Germany?",
    answer:
      "Yes, we provide guidance and support throughout the visa application process, ensuring you have all the required documents and meet the necessary criteria to successfully obtain a visa for Germany.",
  },
  {
    question: "What kind of career support do you offer?",
    answer:
      "We offer personalized career advice, including resume building, interview preparation, and job search strategies tailored to the German job market. We also help connect you with potential employers and opportunities that align with your skills and experience.",
  },
];