import AnimationRevealPage from "helpers/AnimationRevealPage";
//import ClassSchedule from "./ClassSchedule";
import CourseFee from "./CourseFee";
import CourseObjective from "./CourseObjective";
import FaqPage from "components/FAQ";
import FooterPage from "components/Footer";
import Overview from "./Overview";
//import RegistrationForm from "./RegistrationForm";
import SEO from "components/seo/SEO";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { courseFaqs } from "data/dummyData";
import { courseFeeData } from "data/dummyData";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";
import RegistrationForm from "./RegistrationForm";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;


function OnlineGroupCourse() {
  return (
    <AnimationRevealPage>
       <SEO
        title="German Online Group Course - Weg2De Akademie"
        description="German Online Group Course | Learn German Online | German Individual Courses | German for Professionals "
        name="Weg2De - Akademie"
        type=""
      />
      <Container>
        <Overview />
        <CourseObjective />
        {/*<ClassSchedule />*/}
        <CourseFee data={courseFeeData} />
        <RegistrationForm/>
        <FaqPage
          subheading="FAQS"
          heading="Do you have Questions?"
          faqs={courseFaqs}
        />
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default OnlineGroupCourse;
