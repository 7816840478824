import {
  HighlightedText,
  MainHeading,
  Subheading,
} from "components/misc/Headings";

import AnimationRevealPage from "helpers/AnimationRevealPage";
import FooterPage from "components/Footer";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`mt-32 relative max-w-screen-xl mx-auto`;
const HorizontalLine = tw.hr`border-gray-100 my-5`;
const Wrapper = tw.section`mt-10`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

function ImpressumPage() {
  return (
    <AnimationRevealPage>
      <Container>
        <MainHeading>
          Impressum/<HighlightedText>Imprint</HighlightedText>
        </MainHeading>
        <Wrapper>
          <SectionDescription>Angaben gemäß §5 TMG:</SectionDescription>
          <address>
         
              Weg2De International Company Ltd,
              <br />
              Road 5 House 1, Peace Estate
              <br />
              Baruwa – Lagos<br />
              Nigeria 
          </address>
          <HorizontalLine />
          <Subheading>Vertreten durch: </Subheading>
          <SectionDescription>Ishaq Kokumo <strong>(Founder)</strong></SectionDescription>
          <Subheading>Handelsregister</Subheading>
          <SectionDescription>
            <strong>Eingetragen in:</strong> Nigeria – Corporate Affairs Commission (CAC)
          </SectionDescription>
          <SectionDescription><strong>Registernummer:</strong> RC7316755</SectionDescription>
          <HorizontalLine />
          <Subheading>Kontakt</Subheading>
          <SectionDescription><strong>E-Mail: </strong>info@weg2de.com</SectionDescription>

          <SectionDescription>
            Dieses Impressum gilt auch für die Angebote auf Facebook, Instagram,
            LinkedIn, Youtube und X
          </SectionDescription>
          <Subheading>Haftungsausschluss</Subheading>
          <SectionDescription>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für die Inhalte externer Links. Für den Inhalt der
            verlinkten Seiten sind ausschließlich deren Betreiber
            verantwortlich.
          </SectionDescription>
        </Wrapper>
        <DecoratorBlob1 />
      </Container>
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default ImpressumPage;
